<template>
  <b-modal
    :id="id || 'message-creator-modal'"
    hideHeader
    size="lg"
    scrollable
    body-class="px-3 px-md-5"
    :ok-title="$t('messages.views.list.components.message-creator-modal.ok-title')"
    :cancel-title="$t('messages.views.list.components.message-creator-modal.cancel-title')"
    @ok="createButtonClicked"
    @cancel="resetForm"
    @hidden="resetForm"
  >
    <header class="text-center mb-4" v-if="title">
      <h3>{{ title }}</h3>
      <p>{{ subtitle }}</p>
    </header>

    <hs-group
      :label="$t('messages.views.list.components.message-creator-modal.inputs.to.label')"
      label-class="font-weight-bold"
    >
      <hs-multiselect
        :class="isSearchEmpty"
        v-model="students"
        :placeholder="$t('messages.views.list.components.message-creator-modal.inputs.to.placeholder')"
        :custom-label="customLabel"
        track-by="id"
        :options="studentOptions"
        :tagPlaceholder="$t('messages.views.list.components.message-creator-modal.inputs.to.description')"
        multiple
        taggable
        :allow-empty="false"
        :show-labels="false"
        :options-limit="300"
        :loading="isSearching"
        @search-change="searchMembersByFullName"
      >
        <span slot="noOptions">{{
          $t('messages.views.list.components.message-creator-modal.inputs.to.no-options')
        }}</span>
      </hs-multiselect>
    </hs-group>

    <hs-group
      :label="$t('messages.views.list.components.message-creator-modal.inputs.subject.label')"
      label-class="font-weight-bold"
    >
      <hs-input
        v-model="form.subject"
        :placeholder="$t('messages.views.list.components.message-creator-modal.inputs.subject.placeholder')"
        :state="$v.form.subject.$error ? false : null"
        @blur="$v.form.subject.$touch"
      />
    </hs-group>

    <hs-group
      :label="$t('messages.views.list.components.message-creator-modal.inputs.message.label')"
      label-class="font-weight-bold"
    >
      <b-form-textarea
        no-resize
        rows="6"
        max-rows="6"
        :placeholder="$t('messages.views.list.components.message-creator-modal.inputs.message.placeholder')"
        v-model="form.text"
      />
    </hs-group>

    <hs-group>
      <div class="d-flex flex-wrap">
        <div class="position-relative mr-3" v-for="imageUrl in imagesUrl" :key="imageUrl">
          <img height="64" width="95" class="rounded" :src="imageUrl" />
          <div class="text-right rounded-bottom img-url-options-container">
            <hs-button class="p-0 mr-1" icon="trash-alt" variant="transparent" @click="removeSelectedImage(imageUrl)" />
          </div>
        </div>
        <hsImageUploader height="84" width="95" :image="null" multiple @onMultipleFile="onMultipleFile">
          <div class="text-center" v-if="isUploading">
            <b-spinner small type="grow" variant="primary" />
          </div>
          <template v-else>
            <div class="font-size-xs text-center mb-1">
              <p class="mb-2">
                {{ $t('messages.views.list.components.message-creator-modal.inputs.image-input.uploader.label') }}
              </p>
              <hs-icon class="font-size-lg text-primary" variant="light" icon="cloud-upload" />
            </div>
          </template>
        </hsImageUploader>
      </div>
    </hs-group>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import debounce from 'lodash.debounce';
import { uploadService, studentService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'MessageCreatorModal',
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    selectedStudents: {
      type: Array,
    },
  },
  data() {
    return {
      students: [],
      studentOptions: [],
      form: {
        subject: null,
        text: null,
      },
      imagesUrl: [],
      isSearching: false,
      isUploading: false,
    };
  },
  components: {
    hsImageUploader: () => import('@/components').then(comp => comp.hsImageUploader),
  },
  validations: {
    form: {
      subject: {
        required,
      },
      text: {
        required,
      },
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    studentsIds() {
      return this.students.map(user => user.id);
    },
    isSearchEmpty() {
      if (!this.studentOptions.length) return 'emptySelect';
      return '';
    },
    formToSave() {
      return {
        channel: { permission_key: 'school_management', subject: this.form.subject, user_ids: this.studentsIds },
        message: {
          text: `<p>${this.form.text}</p> ${this.imagesUrl.map(
            img =>
              `<img alt="${
                this.form.subject
              }" src="${img}" width="500" height="400" style="max-width: 100%; height: auto;">`
          )}`,
        },
      };
    },
  },
  methods: {
    createButtonClicked() {
      if (this.$v.form.$invalid) return;

      this.$emit('createButtonClick', {
        ...this.formToSave,
      });
      this.resetForm();
    },
    resetForm() {
      const { form, students, imagesUrl } = this.$options.data.call(this);
      this.form = form;
      this.students = students;
      this.imagesUrl = imagesUrl;
      this.$v.$reset();
    },
    async onMultipleFile(files) {
      this.isUploading = true;
      Promise.all(
        files.map(async file => {
          const { data } = await uploadService.getAwsKeys(file.type, this.schoolId);
          const imgURL = await uploadService.upload(data.url, data.fields, file);

          return imgURL;
        })
      )
        .then(imgURL => (this.imagesUrl = [...this.imagesUrl, ...imgURL]))
        .catch(() =>
          ToastHelper.dangerMessage(this.$t('messages.views.list.components.message-creator-modal.toast.error'))
        )
        .finally(() => (this.isUploading = false));
    },
    customLabel({ first_name, email }) {
      return `${first_name} (${email})`;
    },
    removeSelectedImage(imageUrl) {
      this.imagesUrl = this.imagesUrl.filter(imgUrl => imgUrl !== imageUrl);
    },
    searchMembersByFullName: debounce(function(fullName = '') {
      this.isSearching = true;
      studentService
        .getStudents([{ key: 'fields', value: 'id,first_name,last_name,email' }, { key: 'full_name', value: fullName }])
        .then(({ data: { students } }) => (this.studentOptions = students))
        .catch(() =>
          ToastHelper.dangerMessage(
            this.$t('messages.views.list.components.message-creator-modal.toast.error-members-list')
          )
        )
        .finally(() => (this.isSearching = false));
    }, 500),
  },
  created() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'message-creator-modal') {
        this.searchMembersByFullName();
      }
    });
  },
  watch: {
    selectedStudents: {
      handler(value) {
        this.students = value;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.img-url-options-container {
  background: $grey-10;
}
/deep/ .emptySelect .multiselect__content li:last-child {
  display: block !important;
}
</style>
